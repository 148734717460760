import React from 'react'
import styled from 'styled-components'
import styles from './styles'

// hooks
import { useThemeColors } from '@Hooks'

// assets
import { IoIosAddCircle } from 'react-icons/io'

// data[{headline: '' , content: '' }]
const Block = styled.div`${styles}`
const Accordion = ({ data }) => { 
  const [ item, setItem ] = React.useState(0)
  const colors = useThemeColors()

  return (
    <Block colors={colors}>
      {data.map((faq, index) =>
        <div
          role="button"
          key={index}
          onClick={() => setItem(index)}
          onKeyDown={() => setItem(index)}
          className={`item ${item === index ? 'active' : ''}`}
          tabIndex={0}
        >
          <div className="headline">
            <h5>{faq.headline}</h5>
            <IoIosAddCircle />
          </div>
          <div className="content">
            <p>{faq.content}</p>
          </div>
        </div>
      )}
    </Block>
  )
}

export default Accordion