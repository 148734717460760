import styled, { css } from 'styled-components'
import { card } from '@Mixins'

export default css`
  ${card({ radius: '10px ' })};
  width: 100%;
  height: 100%;
  width: 300px;
  min-height: 250px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.88;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  .pet-name {
    position: absolute;
    top: 0;
    left: 0;
    top: -20%;
    z-index: 999;
    width: 120px;
    height: 120px;
    opacity: 1;
  }

  button {
    position: absolute;
    bottom: -12%;
    right: 0;
    opacity: 1;
  }

  // variants
  &.big {
    max-width: 450px;
    width: 100%;

    @media(max-width: 660px) {
      width: 320px;
    }

    @media(max-width: 530px) {
      width: 290px;
      min-height: 0; 
    }

    .pet-name {
      width: 180px;
      height: 180px;
      h3 {
        font-size: 28px;
        text-align: center;
        @media(max-width: 992px) {
          font-size: 22px;
        }
      }
      @media(max-width: 992px) {
        width: 120px;
        height: 120px;
      }
    }
  }
`

export const Headline = styled.h3`
  margin: 0;
  color: white;
`

export const Content = styled.div`
  margin-top: 140px;
  margin-bottom: 25px;

  @media(max-width: 530px) {
    font-size: 14px;
    margin-top: 105px;
  }

  // variants
  &.big {
    margin-top: 215px;
    font-size: 20px;
    @media(max-width: 992px) {
      margin-top: 140px;
    }
    @media(max-width: 530px) {
      font-size: 14px;
      margin-top: 105px;
    }
  }
`

export const ImageContainer = styled.div`
  ${card({ radius: '10px' })};
  padding: 0;
  overflow: hidden;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 200px;
  height: 200px;
  right: -10%;
  top: -20%;
  transform: rotate(-6deg);

  img {
    margin-bottom: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media(max-width: 530px) {
    width: 150px;
    height: 150px;
    right: 0;
  }

  // variants
  &.big {
    width: 280px;
    height: 280px;
    @media(max-width: 992px) {
      width: 200px;
      height: 200px;
    }
    @media(max-width: 530px) {
      width: 150px;
      height: 150px;
      right: 0;
    }
  }
`

export const Date = styled.p`
  font-size: 12px;
  color: #be7272;
  margin-bottom: 0;
  font-weight: bold;
  position: absolute;
  bottom: 15px;
`

