import { css } from 'styled-components'

export default css`
  margin-bottom: 40px;
  margin-top: 40px;
  h2 {
    font-size: 48px;
    color: #61a1c8;
    text-align: right;
  }
  .stories-container {
    
  }
  .stories-items-container {
    margin-top: 0px;
    & > div {
      margin: 75px 0;
    }
  }
`