import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// hooks
import { useThemeColors } from '@Hooks'

// assets
import { Wave } from '@Svgs'

const Section = styled.section`${styles}`
const Services2 = () => {
  const colors = useThemeColors()
  const image = useStaticQuery(graphql`
    query {
      cat: file(relativePath: { eq: "cat.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      dornovaMetoda: file(relativePath: { eq: "dornova-metoda.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      magnetoterapie: file(relativePath: { eq: "magnetoterapie.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      bankovani: file(relativePath: { eq: "bankovani.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      homeopatie: file(relativePath: { eq: "homeopatie.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 600,
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Section colors={colors}>
      <Container className="rehab-container">
        <Row>
          <Col className="headline-holder">
            <h2>Co pro Vás můžeme udělat?</h2>
          </Col>
        </Row>
        <Row id="services-container">
          <Col md="6">
            <div className="service-card first">
              <Link to="/dornova-metoda">
                <Img
                  className="photo"
                  fluid={image.dornovaMetoda.childImageSharp.fluid} 
                  alt="Dornova metoda"
                />
              </Link>
              <div>
                <h3>Dornova metoda</h3>
                <p>Dornova metoda je jemná manuální technika, kterou se navracejí klouby a kosti do jejich fyziologické polohy. Princip Dornovy metody je založen na ošetření celého těla, kloub po kloubu, kůstku po kůstce a nesoustředí se jen na postižené místo. Terapie probíhá tak, že terapeut aktivně pracuje a rozhýbe daný kloub, přitom jemným tlakem tlačí určitým směrem, aby se kloub vrátil na původní místo.</p>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="service-card second">
              <div>
                <h3>Magnetoterapie</h3>
                <p>Tato metoda využívá působení nízkofrekvenčního magnetického pole na tkáně. Magnetické pole působí na buněčné membrány, na enzymy, na nervové buňky a urychluje tak látkovou výměnu. Účinky jsou regenerační, protizánětlivé, hojivé, detoxikační, analgetické a myorelaxační. Magnetoterapie se dá vhodně kombinovat s manuálními technikami. Zvíře leží na magnetické podložce 20 - 60 minut.</p>
              </div>
              <Link to="/magnetoterapie">
                <Img
                  className="photo"
                  fluid={image.magnetoterapie.childImageSharp.fluid} 
                  alt="Magnetoterapie"
                />
              </Link>
            </div>
          </Col>
          <Col md="6">
            <div className="service-card third">
            <Link to="/bankovani">
              <Img
                className="photo"
                fluid={image.bankovani.childImageSharp.fluid} 
                alt="Baňkování"
              />
            </Link>
              <div>
                <h3>Baňkování</h3>
                <p>Baňkování používám jako doplňkovou metodu k manuálním terapiím. Baňkování funguje na principu podtlaku, který dokáže "vcucnout" kůži a podkoží dovnitř do baňky. Při tomto procesu se tkáně lépe uvolní a dojde k zlepšení toku krve a lymfy v daném místě. Před baňkováním se musí konkrétní místo oholit.</p>
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="service-card fourth">
              <div>
                <h3>Osteodynamika</h3>
                <p>Osteodynamika je celostní ošetření opěrného aparátu a fascií lidského nebo zvířecího těla. Při ošetření se velmi jemně pracuje s kostmi, klouby, pojivovými tkáněmi a jejich vzájemným postavením a propojením. Principem Osteodynamiky je působení menší terapeutické síly za delší časovou jednotku při současném pohybu pacienta. Jemná tlaková síla působící delší dobu dává tělu dostatek prostoru na změnu vzorců ve tkáních.</p>
              </div>
              <Link to="/osteodynamika">
                <Img
                  className="photo"
                  fluid={image.cat.childImageSharp.fluid}
                  alt="Kočka"
                />
              </Link>
            </div>
          </Col>
          <Col md="6">
            <div className="service-card fifth">
              <Link to="/homeopatie">
                <Img
                  className="photo"
                  fluid={image.homeopatie.childImageSharp.fluid} 
                  alt="Homeopatie"
                />
              </Link>
              <div>
                <h3>Homeopatie</h3>
                <p>Homeopatie je 200 let stará léčebná metoda, která pohlíží na pacienta jako na celek, který je jako celek je také léčen. Principem alopatie (moderní medicíny) je léčení látkami, které potlačují symptomy dané nemoci, „léčba opakem“. Oproti tomu principem homeopatie je „léčba stejným“. To znamená, že se pacientovi podávají látky, které způsobují stejné symptomy jako jeho onemocnění. Tyto látky se podávají ve velmi malých množstvích (ředěních) a mají za úkol nastartovat samoléčebné procesy v těle pacienta.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Wave />
    </Section>
  )
}

export default Services2