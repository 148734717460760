import React from 'react';
import styled from 'styled-components'
import styles, { WelcomeSection } from './_styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Helmet from 'react-helmet'

// layouts
import RootLayout from '@Layouts/root'

// sections
import { default as Services } from '@Sections/services2'
import Faq from '@Sections/faq'
import Pricing from '@Sections/pricing'
import Stories from '@Sections/stories'

// blocks 
import Arnostek from '@Blocks/arnostek'

// elements
import Shape from '@Elements/shape'
import Button from '@Elements/button'

// assets
import {
  FaRocketchat,
  FaClock,
  FaDog,
} from 'react-icons/fa'
import arnostekIMG from '@Images/arnost-head.png'
import { navigate } from 'gatsby-link';

const Page = styled.div`${styles}`
const IndexPage = () => {
  const title = 'Vítejte páníčkové | Psírehabilitace.cz'
  const description = 'Tohle je popisek stránky pro SEO. Pár klíčových slov a maximální délka. Otestovat v nějakých nástrojích na SERP.'
  const url = 'https://www.psirehabilitace.cz'
  
  return (
    <RootLayout
      link={false}
      headerContent
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="" />
        <meta name="image" content={arnostekIMG} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={arnostekIMG} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="Mvdr. Dominika Bitomská" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={arnostekIMG} />
      </Helmet>
      <Page>
        <Arnostek />
        <WelcomeSection>
          <Container className="rehab-container">
            <Row>
              <Col md="4">
                <h1>Vítejte, páníčkové!</h1>
                <p style={{ color: 'grey' }}>Vítejte na stránkách o manuálních terapiích zvířat. Jmenuji se Dominika Bitomská, jsem veterinární lékařka a doporučený terapeut Dornovy metody pro zvířata.</p>
                <Button background="#b66060" handler={() => navigate('/o-nas')}>O Nás</Button>
              </Col>
              <Col md="8" className="center">
                <Row className="dots">
                  <Col xs="4" sm="4" className="center">
                    <Shape rotation="0deg" background="#544bad">
                      <div><FaRocketchat /></div>
                    </Shape>
                    <span className="h2">Konzultace a posouzení</span>
                  </Col>
                  <Col xs="4" sm="4" className="center">
                    <Shape rotation="40deg" background="#4c95c2">
                      <div><FaClock /></div>
                    </Shape>
                    <span className="h2">Domluvení termínu a ceny</span>
                  </Col>
                  <Col xs="4" sm="4" className="center">
                    <Shape rotation="80deg" background="#249da4">
                      <div><FaDog /></div>
                    </Shape>
                    <span className="h2">Ošetření a provedení terapie</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </WelcomeSection>
        <Services />
        <Pricing />
        <Stories />
        <Faq />
      </Page>
    </RootLayout>
  )
} 
export default IndexPage
