import styled, { css } from 'styled-components'

export default css``

export const WelcomeSection = styled.section`
  margin-top: -450px;

  & > div > div {

  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;        
    font-family: 'Kalam', cursive;
  }

  h1 {
    font-size: 54px;
    color: #62a2c9;
    max-width: 320px;
    margin-bottom: 25px;
  }

  .h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
    max-width: 180px;
    font-family: Kalam, cursive;
    line-height: 1.2;
  }

  p {
    max-width: 320px;
    color: #2c2c2c;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 1200px) {
    margin-top: -340px;

    .md {
      width: 100px !important;
      height: 100px !important;
      font-size: 50px !important;
    }
    .h2 {
      font-size: 19px;
    }
  }

  @media(max-width: 767px) {
    margin-top: -240px;

    .dots {
      margin-top: 50px;
      margin-bottom: 10px;
    }

    .md {
      width: 80px !important;
      height: 80px !important;
      font-size: 40px !important;
    }
  }

  @media(max-width: 420px) {
    .md {
      width: 60px !important;
      height: 60px !important;
      font-size: 30px !important;
    }
    .h2 {
      font-size: 14px;
    }
  }
`