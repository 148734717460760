import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'

// hooks
import { useThemeColors } from '@Hooks'

// elements
import Shape from '@Elements/shape'

const Section = styled.section`${styles}`
const Pricing = () => {
  const colors = useThemeColors()

  return (
    <Section colors={colors}>
      <Container className="rehab-container">
        <Row>
          <Col>
            <h2>Kolik Vás to bude stát?</h2>
          </Col>
        </Row>
        <Row className="pricelist-divider">
          <Col className="pricing-item" xs="12" sm="4">
            <Shape size="sm" rotation="0deg" background="#544bad">
              <div>800 Kč</div>
            </Shape>
            <span className="h2">Terapie <br /> na provozovně</span>
          </Col>
          <Col className="pricing-item" xs="12" sm="4">
            <Shape size="sm" rotation="130deg" background="#4c95c2">
              <div>900 Kč</div>
            </Shape>
            <span className="h2">Terapie <br /> v domácím prostředí</span>
          </Col>
          <Col className="pricing-item" xs="12" sm="4">
            <Shape size="sm" rotation="80deg" background="#249da4">
              <div>6 Kč/km</div>
            </Shape>
            <span className="h2">Cena <br /> dopravného</span>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Pricing