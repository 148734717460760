import { css } from 'styled-components'

export default css`
  min-height: 420px;

  h2 {
    font-size: 48px;
    color: #636363;
    max-width: 390px;
  }

  .h2 {
    font-weight: bold;
    margin-top: 15px;
    display: block;
  }

  .sm {
    width: 115px;
    height: 115px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .pricing-item {
    text-align: center;
  }

  .pricelist-divider {
    border-bottom: 2px solid #efece1;
    padding-bottom: 35px;
    box-shadow: 0 4px 2px -2px #395e9e0d;
  }

  @media(max-width: 767px) {
    .sm {
      // width: 80px;
      // height: 80px;
    }
    .pricing-item {
      margin: 20px 0 20px 0;
    }
  }
`