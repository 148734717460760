import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'

// hooks
import { useThemeColors } from '@Hooks'

// blocks
import Accordion from '@Blocks/accordion'
import Arnostek from '@Blocks/arnostek'

const Section = styled.section`${styles}`
const Faq = () => {
  const colors = useThemeColors()
  const data = [
    {
      headline: 'Je pro mého psa vhodné ošetření Dornovou metodou?',
      content: 'Dornova metoda je vhodná pro psy jakéhokoliv stáří i tělesné kondice. Buď jako prevence nebo řešení akutního či chronického problému.',
    },
    {
      headline: 'Musí mít můj pes před ošetřením klidový režim. Jak dlouhý?',
      content: 'Rozhodně ano. Minimum jsou 4 dny před a po terapii. Individuálně to spolu probereme a dostanete doporučení o dalším pohybovém režimu pejska.',
    },
    {
      headline: 'Musí být můj pes vyšetřen veterinárním lékařem před terapií?',
      content: 'Ano. Nepřijímám pacienty s pohybovými problémy, kteří nebyli ortopedicky a neurologicky vyšetřeni a nemají RTG vyšetření. Pokud to stav pacienta vyžaduje, jsou nezbytné i další diagnostické metody. Pokud budete chtít, můžeme se na vyšetření domluvit spolu. Jediná výjimka, kdy nepotřebuji vyšetření před terapií, je prevence, to znamená, že zvíře ještě nemá klinické potíže.',
    },
    {
      headline: 'Co si mám vzít s sebou?',
      content: 'Sportovní oblečení, RTG snímky (pokud nějaké máte), dobrou náladu a samozřejmě pejska.',
    },
    {
      headline: 'Jak se můžu objednat?',
      content: 'Preferuji telefonické objednávky, protože se vás můžu zeptat na všechny důležité informace a společně se pak domluvíme na vhodném termínu. Pokud se nedovoláte na poprvé, prosím, zkoušejte to dál. V mé profesi často není možné ihned zvednout telefon.',
    },
  ]   
  
  return (
    <Section colors={colors}>
      <Container className="rehab-container">
        <Row>
          <Col>
            <h2>Často kladené otázky</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6" className="arnostek-container">
            <Arnostek />
            <div className="question-mark">?</div>
          </Col>
          <Col sm="12" md="6">
            <Accordion data={data} />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Faq