import React from 'react'
import styled from 'styled-components'
import styles from './styles'

const Element = styled.button`${styles}`
const Button = ({ children, handler, background }) => (
  <Element onClick={() => handler()} background={background}>
    {children}
  </Element>
)

Button.defaultProps = {
  handler: () => console.log('empty onlick event'),
  background: '#82b4d3'
}

export default Button