import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Headline, Content, ImageContainer, Date } from './styles'
import { navigate } from 'gatsby'

// elements
import Shape from '@Elements/shape'
import Button from '@Elements/button'

const Block = styled.div`${styles}`
const StoryCard = ({ children, petName, rotation, background, link, imgSrc, date, variant }) => (
  <Block onClick={() => navigate(link)} className={variant}>
    <Shape rotation={rotation} background={background} color="white" className="pet-name">
      <Headline>{petName}</Headline>
    </Shape>
    <ImageContainer className={variant}>
      <img src={imgSrc} alt={petName} />
    </ImageContainer>
    <Button background={background} onClick={() => navigate(link)}>Detail</Button>
    <Content className={variant}>{children}</Content>
    <Date>{date}</Date>
  </Block>
)

StoryCard.defaultProps = {
  rotation: '0deg',
  background: '#544bad',
}

export default StoryCard