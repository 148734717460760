import { css } from 'styled-components'

export default css`
  display: block;
  border: 0;
  padding: 20px 25px;
  font-weight: 600;
  background: ${props => props.background};
  color: white;
  border-top-left-radius: 470px;
  border-top-right-radius: 570px;
  border-bottom-right-radius: 430px;
  border-bottom-left-radius: 580px;
  cursor: pointer;

  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`