import { css } from 'styled-components'
import { card } from '@Mixins'

export default css`
  width: 100%;
  padding: 4rem 0 0 0;
  background: ${props => props.colors.grey};
  background: linear-gradient(to top, ${props => props.colors.grey}, #f7f7f7);
  .headline-holder {
    text-align: right;
  }
  h2 {
    font-size: 48px;
    color: ${props => props.colors.green};
    max-width: 350px;
    float: right;
    margin-bottom: 0;

    @media(max-width: 767px) {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      float: initial;
    }
  }
  #services-container {
    margin-top: 60px;
    margin-bottom: -140px;

    @media(max-width: 767px) {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 180px;
    }
  }
  .service-card {
    max-width: 540px;
    display: flex;
    flex-direction: column;
    ${card({ radius: '25px' })};
    padding-top: 35px;
    h3 {
      font-size: 32px;
      color: ${props => props.colors.blue};
    }
    p {
      color: grey;
    }
    .photo {
      border-radius: 32px;
      box-shadow: 6px 5px 14px 0px #3232316b;
      opacity: 0.8;
      transition: all 500ms ease-out 0.1s;
      cursor: pointer;
      &:hover {
        transition: all 500ms ease-out 0.1s;
        opacity: 1;
      }
    }
    &.first {
      margin-top: -130px;
      .photo {
        transform: rotate(-4deg);
        margin-top: -60px;
        margin-bottom: 30px;
      }
    }
    &.second { 
      .photo {
        transform: rotate(8deg);
        margin-top: 60px;
        margin-bottom: -30px;
      }
      @media(max-width: 767px) {
        margin-top: 55px;
        .photo {
          order: 1;
          transform: rotate(-4deg);
          margin-top: -60px;
          margin-bottom: 30px;
        }
        div {
          order: 2;
        }
      }
    }
    &.third { 
      margin-top: -80px;
      .photo {
        transform: rotate(8deg);
        margin-top: -60px;
        margin-bottom: 30px;
      }
      @media(max-width: 767px) {
        margin-top: 55px;
        .photo {
          order: 1;
          transform: rotate(-4deg);
          margin-top: -60px;
          margin-bottom: 30px;
        }
        div {
          order: 2;
        }
      }
    }
    &.fourth { 
      margin-top: 90px;
      .photo {
        transform: rotate(-8deg);
        margin-top: 60px;
        margin-bottom: -30px;
      }
      @media(max-width: 767px) {
        margin-top: 55px;
        .photo {
          order: 1;
          transform: rotate(-4deg);
          margin-top: -60px;
          margin-bottom: 30px;
        }
        div {
          order: 2;
        }
      }
    }
    &.fifth {
      margin-top: -200px;
      margin-bottom: 130px;
      .photo {
        transform: rotate(8deg);
        margin-top: -65px;
        margin-bottom: 20px;
      }
      @media(max-width: 767px) {
        margin-top: 55px;
        .photo {
          order: 1;
          transform: rotate(-4deg);
          margin-top: -60px;
          margin-bottom: 30px;
        }
        div {
          order: 2;
        }
      }
    }
  }
`