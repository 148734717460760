import { css } from 'styled-components'
import { card } from '@Mixins'

export default css`
  width: 100%;
  display: flex;
  flex-direction: column;

  .item {
    ${card({ radius: '10px ' })};
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    cursor: pointer;
    overflow: hidden;

    &.active {
      .headline {
        background: ${props => props.colors.grey};

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        h5 {
          
        }
      }
      .content {
        padding: 0 30px 15px 30px;
        visibility: visible;
        opacity: 1;
        height: auto;
        overflow: initial;
      }
    }
  }

  .headline {
    padding: 15px 15px 15px 15px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  h5 {
    color: ${props => props.colors.dark};
    margin: 0 0 0 15px;
    font-size: 22px;
    font-family: 'Kalam',cursive;
    font-weight: 400;
    opacity: 0.8;
  }

  .content {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: visibility 0s, opacity 0.5s linear;
  }

  p {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 18px;
    color: grey;
  }
`

/*
        ${props => gradient({
          color1: props.colors.purple,
          color2: props.colors.green,
        })}; 
*/