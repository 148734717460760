import { css } from 'styled-components'

export default css`
  padding: 0 0 6rem 0;
  h2 {
    font-size: 48px;
    color: #249da4;
    max-width: 350px;
    color: ${props => props.colors.purple};
  }
  .arnostek-container {
    #arnostek-container {
      top: 0;
    }
  }
  .question-mark {
    font-size: 15rem;
    color: #544bad;
    position: absolute;
    top: -50px;
    right: 70px;
    font-family: 'Kalam',cursive;
    opacity: 0.8;
    @media(max-width: 767px) {
      font-size: 120px;
      right: 40px;
      top: 10px;
    }
  }
`