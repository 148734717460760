import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// hooks
import { useThemeColors } from '@Hooks'

// blocks
import StoryCard from '@Blocks/story-card'

// elements
import Button from '@Elements/button'

const getRandomBg = () => {
  const purple = '#544bad'
  const blue = '#4c95c2'
  const green = '#249da4'
  const colorsArray = [purple, blue, green]
  const myIndex = Math.floor(Math.random() * colorsArray.length)

  return colorsArray[myIndex]
}

const Section = styled.section`${styles}`
const Stories = () => {
  const data = useStaticQuery(graphql`
    query StoriesQuery {
      allMarkdownRemark(limit: 6, sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              petName
              shortDescription
              petAvatar {
                publicURL
              }
              date
            }
          }
        }
      }
    }
  `)
  const colors = useThemeColors()


  let isShowMoreBtn = false
  const storiesArray = data.allMarkdownRemark.edges
  if (storiesArray.length >= 6) isShowMoreBtn = true

  return (
    <Section colors={colors}>
      <Container className="stories-container">
        <Row>
          <Col>
            <h2>Co se nám povedlo?</h2>
          </Col>
        </Row>
        <Row className="stories-items-container">
          {data.allMarkdownRemark.edges.map((el) => {
            const img = el.node.frontmatter.petAvatar?.publicURL || '/'
            const date = new Date(el.node.frontmatter.date)
            const month = date.getMonth()+1
            const year = date.getFullYear()
            const day = date.getDate()

            return (
              <Col sm="12" md="6" lg="4">
                <StoryCard
                  background={getRandomBg()}
                  petName={el.node.frontmatter.petName}
                  link={el.node.fields.slug}
                  imgSrc={img}
                  date={`${day}. ${month}. ${year}`}
                >
                {el.node.frontmatter.shortDescription.substring(0, 60)}...
              </StoryCard>
              </Col>
            )
          })}
          {isShowMoreBtn && (
            <Col style={{ marginTop: 0, display: 'flex', justifyContent: 'center' }}>
              <Button background="#b66060" handler={() => navigate('/pribehy')}>Zobrazit všechny</Button>
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  )
}

export default Stories