import React from 'react'
import styled from 'styled-components'
import styles from './styles'

const Svg = styled.div`${styles}`
const Wave = ({ size, maxSize }) => {
  return (
    <Svg
      id="svg-wave" 
      size={size} 
      maxSize={maxSize ? maxSize : undefined}
    >
      <svg 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg"
        x="0px" 
        y="0px"
        viewBox="0 0 1440 320"
        stroke="currentColor"
        strokeWidth="0"
        fill="green"
      >
        <path fill="#f7f7f7" fillOpacity="1" d="M0,160L40,170.7C80,181,160,203,240,181.3C320,160,400,96,480,106.7C560,117,640,203,720,229.3C800,256,880,224,960,224C1040,224,1120,256,1200,224C1280,192,1360,96,1400,48L1440,0L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
      </svg>
    </Svg>
  )
}

Wave.defaultProps = {
  size: 100,
}

export default Wave




